import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import "./style.css";

import Header from "../components/header";

const NotFoundPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  );
  return (
    <div id="nf">
      <Helmet title={`404 | ${site.siteMetadata?.title}`} />
      <Header />
      <h1 className="title">404</h1>
      <p className="subtitle">Halaman Tidak Ditemukan :(</p>
    </div>
  );
};

export default NotFoundPage;
